/* Global Styling */
.App {
  font-family: Arial, sans-serif;
  text-align: center;
  background-color: #f8f9fa;
  padding: 2em;
}

/* Header Styling */
.header {
  background-color: #1F3E76;
  color: white;
  padding: 1.5em 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-size: 2rem;
}

.header h1 {
  margin: 0;
}

/* Sticky Header Styling */
.sticky-header {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #f8f9fa;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out; /* Smooth transition for sticky changes */
  padding: 3em;
}

.sticky-header.sticky {
  display: flex;
  justify-content: center; /* Center content in sticky state */
  align-items: center;
  padding: 0.5em; /* Smaller padding in sticky mode */
  transform: translateY(0); 
}

.sticky-header .logo-only {
  max-width: 150px; /* Smaller logo in sticky mode */
  height: auto;
  transition: transform 0.3s ease-in-out, opacity 2s ease-in-out;
}

.sticky-header:not(.sticky) .logo-only {
  display: none; /* Hide logo-only element when not sticky */
}

.sticky-header.sticky h1,
.sticky-header.sticky p {
  display: none; /* Hide additional content when sticky */
}

/* Large Heading Styling */
.display-3 {
  font-size: 2rem; /* Adjusted font size for large heading */
  margin: 0.5em 0;
  text-align: center;
  animation: slideDown 1s ease-in-out; /* Animation for smooth slide down */
}

.logo-main {
  max-width: 300px;
  height: auto;
  margin: 0 auto; /* Center horizontally */
  display: block;
  animation: fadeIn 1s ease-in-out; /* Animation for fading in */
  transition: transform 0.6s ease-in-out, opacity 2s ease-in-out;
}

/* Welcome Animation Styling */
.welcome-animation {
  animation: slideDown 1s ease-in-out; /* Animation for sliding down */
}



/* Fade-in Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Slide-down Animation */
@keyframes slideDown {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Styling for the "lead" class */
.lead {
  font-size: 1.2rem; /* Slightly larger font for emphasis */
  font-weight: 400; /* Normal font weight */
  color: #333; /* Dark gray for readability */
  line-height: 1.6; /* Comfortable line spacing */
  text-align: center; /* Center the text */
  max-width: 600px; /* Restrict width for readability */
  margin: 0 auto; /* Center align horizontally */
}

/* Utility class for margin-top */
.mt-3 {
  margin-top: 1rem; /* Adds top margin to create spacing */
}

/* Base styling for each section */
section {
  background-color: white;
  padding: 1.5em;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin: 1em 0;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Hover effect for sections */
section:hover {
  transform: translateY(-8px); /* Slight upward movement */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Enhanced shadow */
}

/* Mission and Goals sections styling */
.info-sections {
  display: flex;
  justify-content: space-around;
  gap: 1em;
  padding: 1em 0;
}

.info-sections section {
  width: 45%;
}

.info-sections h2 {
  color: #003366;
  font-size: 1.5rem;
  margin-bottom: 1em;
}

/* Employee Benefits section styling */
.benefits {
  background-color: #e9f3ff;
  padding: 2em;
  border-radius: 8px;
  max-width: 98%; /* Center the section with a max-width */
  margin: 2em auto;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.benefits h2 {
  color: #004080;
  margin-bottom: 1em;
  text-align: center; /* Center heading */
}

.benefits ul {
  list-style-type: none;
  padding: 0;
}

.benefits li {
  font-size: 1.1em;
  margin-bottom: 0.5em;
  padding-left: 1.5em;
  position: relative;
}

.benefits li:before {
  content: '✓';
  color: #004080;
  font-weight: bold;
  position: absolute;
  left: 0;
}

/* Latest News section styling */
.news {
  background-color: #fefefe;
  padding: 2em;
  border-radius: 8px;
  max-width: 98%; /* Center the section with a max-width */
  margin: 2em auto;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.news h2 {
  color: #003366;
  margin-bottom: 1em;
  text-align: center; /* Center heading */
}

/* Support section styling with animation */
.support {
  background-color: #e9f3ff;
  padding: 2em;
  border-radius: 8px;
  max-width: 98%;
  margin: 1em auto;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.support:hover {
  transform: translateY(-8px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.support h2 {
  color: #003366;
  margin-bottom: 1em;
}

.support .btn {
  width: 200px;
  font-size: 1rem;
  margin: 0.5em;
  padding: 0.8em;
  border-radius: 6px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

/* Button color variants */
.support .btn-primary {
  background-color: #007bff;
  border: none;
}

.support .btn-primary:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.support .btn-secondary {
  background-color: #6c757d;
  border: none;
}

.support .btn-secondary:hover {
  background-color: #545b62;
  transform: scale(1.05);
}

.support .btn-warning {
  background-color: #ffc107;
  border: none;
}

.support .btn-warning:hover {
  background-color: #d39e00;
  transform: scale(1.05);
}

.support .btn-success {
  background-color: #28a745;
  border: none;
}

.support .btn-success:hover {
  background-color: #218838;
  transform: scale(1.05);
}

/* Helpful Links section styling with animation */
.helpful-links {
  background-color: #f0f0f0;
  padding: 2em;
  border-radius: 8px;
  max-width: 98%;
  margin: 1em auto;
  text-align: left;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.helpful-links:hover {
  transform: translateY(-8px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.helpful-links h2 {
  color: #003366;
  font-size: 1.5rem;
  margin-bottom: 1em;
}

.helpful-links ul {
  list-style-type: none;
  padding: 0;
}

.helpful-links li {
  font-size: 1em;
  margin-bottom: 0.5em;
}

.helpful-links a {
  color: #0056b3;
  text-decoration: none;
  transition: color 0.3s ease;
}

.helpful-links a:hover {
  color: #003366;
  text-decoration: underline;
}

/* Kevin's Korner section styling */

/* Kevin's Korner section styling */
.kevins-korner {
  background-color: #f1f0f0;
  padding: 2em;
  border-radius: 8px;
  margin: 2em auto;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 98%;
  display: flex; /* Flexbox for layout */
  align-items: flex-start; /* Align items to the top */
  gap: 1.5em; /* Space between image and content */
  position: relative; /* For advanced positioning if needed */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.kevins-korner:hover {
  transform: translateY(-8px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Picture section */
.picture-section {
  flex-shrink: 0; /* Prevent the image from resizing */
}

.kevins-picture {
  max-width: 120px; /* Adjust size as needed */
  height: auto;
  border-radius: 50%; /* Circular image */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

/* Content section */
.content-section {
  flex: 1; /* Take up remaining space */
  text-align: left;
}

.kevins-korner h2 {
  color: #003366;
  margin-bottom: 1em;
  font-size: 1.5rem; /* Adjust heading size */
}

.kevins-korner p {
  font-size: 1.1em;
  line-height: 1.6;
  color: #333; /* Slightly darker text for readability */
}

.nav-link.active {
  background-color: #1F3E76;
  color: #fff !important; /* Ensure text is visible on a dark background */
  border-radius: 0.25rem;  /* Slightly rounded corners (optional) */
  /* Add any other styling you want, for example: */
  /* font-weight: bold; */
  /* padding: 8px 12px; */
}